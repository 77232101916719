const images2020 = [
  {
    filename: '2020/IMG_3336.jpg',
    alt: '',
  },
  {
    filename: '2020/IMG_3507.jpg',
    alt: '',
  },
  {
    filename: '2020/IMG_4171.jpg',
    alt: '',
  },
  {
    filename: '2020/IMG_4472.jpg',
    alt: '',
  },
  {
    filename: '2020/IMG_4810.jpg',
    alt: '',
  },
  {
    filename: '2020/IMG_5057.jpg',
    alt: '',
  },
  {
    filename: '2020/IMG_5058.jpg',
    alt: '',
  },
  {
    filename: '2020/IMG_5333.jpg',
    alt: '',
  },
  {
    filename: '2020/IMG_5400.jpg',
    alt: '',
  },
  {
    filename: '2020/IMG_5417.jpg',
    alt: '',
  },
  {
    filename: '2020/IMG_5510.jpg',
    alt: '',
  },
  {
    filename: '2020/IMG_5556.jpg',
    alt: '',
  },
  {
    filename: '2020/IMG_5564.jpg',
    alt: '',
  },
  {
    filename: '2020/IMG_5687.jpg',
    alt: '',
  },
  {
    filename: '2020/IMG_5797.jpg',
    alt: '',
  },
  {
    filename: '2020/IMG_6194.jpg',
    alt: '',
  },
  {
    filename: '2020/IMG_6786.jpg',
    alt: '',
  },
  {
    filename: '2020/IMG_6858.jpg',
    alt: '',
  },
  {
    filename: '2020/IMG_6921.jpg',
    alt: '',
  },
  {
    filename: '2020/IMG_7334.jpg',
    alt: '',
  },
  {
    filename: '2020/IMG_7454.jpg',
    alt: '',
  },
  {
    filename: '2020/IMG_7706.jpg',
    alt: '',
  },
  {
    filename: '2020/IMG_E3374.jpg',
    alt: '',
  },
  {
    filename: '2020/IMG_E4380.jpg',
    alt: '',
  },
  {
    filename: '2020/IMG_E6305.jpg',
    alt: '',
  },
]

export default images2020;