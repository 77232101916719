const images2022 = [
  {
    filename: '2022/IMG_0037.jpg',
    alt: 'At The Music Man',
  },

  {
    filename: '2022/IMG_7398.jpg',
    alt: '',
  },
  {
    filename: '2022/IMG_7757.jpg',
    alt: '',
  },
  {
    filename: '2022/IMG_7989.jpg',
    alt: '',
  },
  {
    filename: '2022/IMG_8105.jpg',
    alt: '',
  },
  {
    filename: '2022/IMG_8158.jpg',
    alt: '',
  },
  {
    filename: '2022/IMG_8199.jpg',
    alt: '',
  },
  {
    filename: "2022/IMG_0064.jpg",
    alt: "",
  },
  {
    filename: "2022/IMG_0397.jpg",
    alt: "",
  },
  {
    filename: "2022/IMG_0450.jpg",
    alt: "",
  },
  {
    filename: "2022/IMG_0580.jpg",
    alt: "",
  },
  {
    filename: "2022/IMG_0663.jpg",
    alt: "",
  },
  {
    filename: "2022/IMG_9025.jpg",
    alt: "",
  },
  {
    filename: "2022/IMG_9131.jpg",
    alt: "",
  },
  {
    filename: "2022/IMG_9533.jpg",
    alt: "",
  },
]

export default images2022;
