import React, { createRef, useRef, useState, useEffect } from 'react';
import './App.css';
import images2020 from "./photoLists/2020.js";
import images2022 from "./photoLists/2022.js";
import images2023 from "./photoLists/2023.js";
import images2024 from "./photoLists/2024.js";

const imageFolder = require.context('../public/images', true);

const years = [
  { year: '2024', data: images2024 },
  { year: '2023', data: images2023 },
  { year: '2022', data: images2022 },
  { year: '2020-21', data: images2020 }
];

function App() {
  const [yearOpen, setYearOpen] = useState(false);
  const [images, setImages] = useState(images2024);
  const [currentImage, setCurrentImage] = useState(null);
  const [fade, setFade] = useState(false);
  const imagesRef = useRef(images.map(() => createRef()));

  useEffect(() => {
    if (currentImage !== null) {
      const imgRef = imagesRef.current[currentImage];
      imgRef.current.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }
  }, [currentImage]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'ArrowRight' || event.key === 'ArrowDown') {
        event.preventDefault();
        setCurrentImage((prev) => ((prev ?? -1) + 1) % images.length);
      } else if (event.key === 'ArrowLeft' || event.key === 'ArrowUp') {
        event.preventDefault();
        setCurrentImage((prev) => ((prev ?? 0) - 1 + images.length) % images.length);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [images]);

  const handleYearChange = (yearImages) => {
    setFade(true);
    setTimeout(() => {
      setImages(yearImages);
      setCurrentImage(null);
      window.scrollTo({ top: 0 });
      setFade(false);
      setYearOpen(false);
    }, 750); // Match the CSS transition duration
  };

  const handleClick = (index) => {
    setCurrentImage((prev) => prev === index ? null : index);
  };


  return (
    <div className="App">
      <div className="background"></div>
      <div className={`imagesPanel ${fade ? 'fade-out' : ''}`}>
        <div className="images">
          {images.map((image, index) => {
            const isSelected = index === currentImage;
            const evenOdd = index % 2 === 0 ? 'odd' : 'even';
            const rotate = Math.random() * 10;
            const style = {
              transform: evenOdd === 'odd'
                ? `translateX(40%) rotate(${7 + rotate}deg)`
                : `translateX(-40%) rotate(${-7 - rotate}deg)`
            };
            return (
              <div
                className={`imageBox ${evenOdd} ${isSelected ? 'selected' : 'not-selected'}`}
                key={image.filename}
                onClick={() => handleClick(index)}
                style={style}
                role="button"
                tabIndex={0}
                aria-label={`Image ${index + 1}`}
              >
                <img
                  className="image"
                  ref={imagesRef.current[index]}
                  src={imageFolder(`./${image.filename ?? ''}`)}
                  alt={image.alt ?? ''}
                />
                <div className="text">
                  <div className="location">{image.loc ?? ""}</div>
                  {image.alt ?? ""}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="title">Mindy + Justin</div>
      <div className="contentBox">
        <div className="content">
          {years.map(({ year, data }) => {
            if (!yearOpen && images !== data) {
              return null
            }
            return (
              <div
                key={year}
                className={`year ${yearOpen && images === data && "selected"}`}
                onClick={() => yearOpen ? handleYearChange(data) : setYearOpen(true)}
                role="button"
                tabIndex={0}
                aria-label={`Select year ${year}`}
              >
                {year}
              </div>
            )}
          )}
        </div>
      </div>
    </div>
  );
}

export default App;
