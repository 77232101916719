const images2024 = [
  {
    filename: "2024/IMG_4159.jpg",
    loc: "NYC",
    alt: "NY apartment cleared out",
  },
  {
    filename: "2024/IMG_4176.jpg",
    loc: "Puerto Rico",
    alt: "Puerto Rico Airbnb",
  },
  {
    filename: "2024/IMG_4288.jpg",
    loc: "Puerto Rico",
    alt: "Playa Carolina",
  },
  {
    filename: "2024/IMG_4333.jpg",
    loc: "Puerto Rico",
    alt: "Mangroves",
  },
  {
    filename: "2024/IMG_5109.jpg",
    loc: "Idaho",
    alt: "Dierkes Lake",
  },
  {
    filename: "2024/IMG_5203.jpg",
    loc: "Utah",
    alt: "Arches Nat'l Park",
  },
  {
    filename: "2024/IMG_5228.jpg",
    loc: "Texas",
    alt: "Crossing the border",
  },
  {
    filename: "2024/IMG_5299.jpg",
    loc: "Texas",
    alt: "Cameron Park Zoo",
  },
  {
    filename: "2024/IMG_5338.jpg",
    loc: "Texas",
    alt: "Eclipse, cloudy",
  },
  {
    filename: "2024/IMG_5402.jpg",
    loc: "Texas",
    alt: "Waco Suspension Bridge",
  },
  {
    filename: "2024/IMG_5598.jpg",
    loc: "Texas",
    alt: "Houston Temple",
  },
  {
    filename: "2024/IMG_5664.jpg",
    loc: "New Mexico",
    alt: "Carlsbad Caverns",
  },
  {
    filename: "2024/IMG_5706.jpg",
    loc: "Arizona",
    alt: "Petrified Forest National Park",
  },
  {
    filename: "2024/IMG_5726.jpg",
    loc: "Arizona",
    alt: "Bedrock (RV park)",
  },
  {
    filename: "2024/IMG_5801.jpg",
    loc: "Arizona",
    alt: "Grand Canyon",
  },
  {
    filename: "2024/IMG_5867.jpg",
    loc: "Utah",
    alt: "Draper",
  },
  {
    filename: "2024/IMG_5880.jpg",
    loc: "Idaho",
    alt: "Shoshone Falls",
  },
  {
    filename: "2024/IMG_5995.jpg",
    loc: "Illinois",
    alt: "Nauvoo Temple",
  },
  {
    filename: "2024/IMG_6047.jpg",
    loc: "Maine",
    alt: "Camden",
  },
  {
    filename: "2024/IMG_6137.jpg",
    loc: "Maine",
    alt: "Acadia Nat'l Park",
  },
  {
    filename: "2024/IMG_6152.jpg",
    loc: "Maine",
    alt: "Kennebunkport",
  },
  {
    filename: "2024/IMG_6163.jpg",
    loc: "New Hampshire",
    alt: "Lake Winnipesaukee",
  },
  {
    filename: "2024/IMG_6215.jpg",
    loc: "Utah",
    alt: "Onegin Ballet",
  },
  {
    filename: "2024/IMG_6256.jpg",
    loc: "New York",
    alt: "Niagara Falls",
  },
  {
    filename: "2024/IMG_6265.jpg",
    loc: "Canada",
    alt: "Rainbow Bridge",
  },
  {
    filename: "2024/IMG_6349.jpg",
    loc: "Wyoming",
    alt: "The Playmill, Anastasia",
  },
  {
    filename: "2024/IMG_6445.jpg",
    loc: "Pennsylvania",
    alt: "Philadelphia Temple",
  },
  {
    filename: "2024/IMG_6498.jpg",
    loc: "NYC",
    alt: "86st & Broadway Subway",
  },
  {
    filename: "2024/IMG_6532.jpg",
    loc: "NYC",
    alt: "NY Public Library, Patience",
  },
  {
    filename: "2024/IMG_6574.jpg",
    loc: "Connecticut",
    alt: "Lobster Landing",
  },
  {
    filename: "2024/IMG_6645.jpg",
    loc: "Connecticut",
    alt: "A field in a nature preserve",
  },
  {
    filename: "2024/IMG_6659.jpg",
    loc: "Connecticut",
    alt: "Hiking around a lake",
  },
  {
    filename: "2024/IMG_6668.jpg",
    loc: "Connecticut",
    alt: "Ice cream",
  },
  {
    filename: "2024/IMG_6677.jpg",
    loc: "Massachusetts",
    alt: "Boston Temple",
  },
  {
    filename: "2024/IMG_6735.jpg",
    loc: "London",
    alt: "St. Paul's Cathedral",
  },
  {
    filename: "2024/IMG_6810.jpg",
    loc: "England",
    alt: "White Cliffs of Dover",
  },
  {
    filename: "2024/IMG_6838.jpg",
    loc: "London",
    alt: "Trafalgar Square",
  },
  {
    filename: "2024/IMG_6939.jpg",
    loc: "England",
    alt: "Stonehenge",
  },
  {
    filename: "2024/IMG_7063.jpg",
    loc: "Northern Ireland",
    alt: "Giant's Causeway",
  },
  {
    filename: "2024/IMG_7085.jpg",
    loc: "Northern Ireland",
    alt: "Dunluce Castle ruins",
  },
  {
    filename: "2024/IMG_7098.jpg",
    loc: "Northern Ireland",
    alt: "Dark Hedges",
  },
  {
    filename: "2024/IMG_7195.jpg",
    loc: "Northern Ireland",
    alt: "B&B",
  },
  {
    filename: "2024/IMG_7262.jpg",
    loc: "Ireland",
    alt: "Cliffs of Moher",
  },
  {
    filename: "2024/IMG_7279.jpg",
    loc: "Ireland",
    alt: "Really green field at Cliffs of Moher",
  },
  {
    filename: "2024/IMG_7292.jpg",
    loc: "Ireland",
    alt: "Poulnabrone portal tomb (built ~4000BC)",
  },
  {
    filename: "2024/IMG_7324.jpg",
    loc: "Ireland",
    alt: "Killary Fjord",
  },
  {
    filename: "2024/IMG_7366.jpg",
    loc: "UK",
    alt: "Aer Lingus flight to NY",
  },
]

export default images2024;
