const images2023 = [
  {
    filename: "2023/IMG_0953.jpg",
    alt: "",
  },
  {
    filename: "2023/IMG_1174.jpg",
    alt: "",
  },
  {
    filename: "2023/IMG_1223.jpg",
    alt: "",
  },
  {
    filename: "2023/IMG_1294.jpg",
    alt: "",
  },
  {
    filename: "2023/IMG_1769.jpg",
    alt: "",
  },
  {
    filename: "2023/IMG_2024.jpg",
    alt: "",
  },
  {
    filename: "2023/IMG_2037.jpg",
    alt: "",
  },
  {
    filename: "2023/IMG_2126.jpg",
    alt: "",
  },
  {
    filename: "2023/IMG_2143.jpg",
    alt: "",
  },
  {
    filename: "2023/IMG_2335.jpg",
    alt: "",
  },
  {
    filename: "2023/IMG_2519.jpg",
    alt: "",
  },
  {
    filename: "2023/IMG_2722.jpg",
    alt: "",
  },
  {
    filename: "2023/IMG_3183.jpg",
    alt: "",
  },
  {
    filename: "2023/IMG_3297.jpg",
    alt: "",
  },
  {
    filename: "2023/IMG_3530.jpg",
    alt: "",
  },
  {
    filename: "2023/IMG_3595.jpg",
    alt: "",
  },
  {
    filename: "2023/IMG_8494.jpg",
    alt: "",
  },
]

export default images2023;
